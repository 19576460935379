import { en as addCompany } from '@/common/components/addCompanyModal/locales';
import { en as companyGroup } from '@/common/components/companyGroup/locales';
import { en as myCompanies } from '@/resources/my-companies/locales';
import { en as onboarding } from '@/resources/onboarding/locales';
import { en as taskManager } from '@/resources/task-manager/locales';
import { es as digitalCertificates } from '@/resources/digital-certificates/locales';
import { en as automaticTaxInvoiceInbound } from '@/resources/automatic-tax-invoice-inbound/locales';
import { en as menu } from '@/common/components/menu/locales';
import { en as profileManagement } from '@/resources/profile-management/locales';

export default {
  en: {
    ...myCompanies,
    ...addCompany,
    ...companyGroup,
    ...onboarding,
    ...automaticTaxInvoiceInbound,
    ...taskManager,
    ...menu,
    ...digitalCertificates,
    ...profileManagement,
    modals: {
      addCompanyModal: {
        title: 'Register Companies',
        firstTab: {
          title: 'Register Company (CNPJ)',
          body: 'Inform below the CNPJ(s) of the company(s) you wish to register:',
        },
      },
      notFoundParentCompany: {
        title: 'Attention!',
        message: 'Unregistered company, the CNPJ informed is from a branch and its matrix does not exist in our database.',
      },
    },
    onboarding: {
      stepButtons: {
        back: 'Previous',
        nextStep: 'Next',
        jumpStep: 'Skip step',
        finishStep: 'Finish',
      },
      start: {
        text: 'To access your Administrative Panel we need you to inform us some data through a quick configuration...',
        buttonText: 'Let\'s go!',
        support: 'Support',
        hello: 'Hello',
        welcome: 'welcome to ROIT',
      },
      changePassword: {
        pageTitle: 'First, you need to change your password…',
        pageSubtitle: 'To ensure your security, we ask you to change your password on the 1st access *',
        observation: '* Whenever you need or want, you can change your password by accessing your profile, in personal information.',
        user: 'User',
        passwordHint: 'The password must contain 8 or more characters with a combination of letters, numbers and symbols.',
        oldPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        changePasswordButton: 'Change password',
        msgError: 'Password change failed',
        msgPasswordsDontMatch: 'The passwords entered do not match',
        msgErrorNotEqualPasswords: 'New password must be different from current password',
        msgSuccess: 'Password changed successfully!',
        msgWorking: 'Processing the request...',
        rules: {
          minCharacter: 'Minimum of 8 characters',
          hasLyrics: 'Have letters',
          hasNumbers: 'Have numbers',
          hasSpecialCharacters: 'Have special characters',
        },
      },
      registerResponsible: {
        pageTitle: 'We will register those responsible for the product(s)',
        pageSubtitle: 'Registered people will receive invitations by email to access the product(s)',
        iBeResponsible: 'I will be responsible for the products',
        newResponsible: 'Register Responsible',
        clickToRegister: 'Tap the button above to register Responsibles',
        form: {
          name: 'Name',
          surname: 'Surname',
          document: 'CPF',
          mail: 'E-mail',
          job: 'Office',
          namePlaceholder: 'Ex.: Marcelo',
          surnamePlaceholder: 'Ex.: Silva',
          documentPlaceholder: 'Ex.: 000.000.000-00',
          mailPlaceholder: 'Ex.: e-mail@empresa.com.br',
          jobPlaceholder: 'Ex.: Accounting Analyst',
        },
      },
      registerCompany: {
        btnRegister: 'Register Company',
        pageTitle: 'Now, let\'s register your company(s)',
        pageSubtitle: 'Register your company(s), informing all the CNPJ\'s of your business group, whether the companies are related or not:',
        clickToRegister: 'Tap the button above to register your Company(s)',
        table: {
          listMsgError: 'It was not possible to find the registered companies',
          successRegistration: 'Company(s) registered successfully!',
          errorRegistration: 'Error(s) reported: {errors}',
          errorInRegistration: 'It was not possible to register one or more CNPJs',
          successUpdate: 'Company data successfully updated!',
          errorUpdate: 'Unable to update company data',
          successDelete: 'Company(s) deleted successfully!',
          errorDelete: 'Could not delete company',
          errorSearch: 'Unable to search',
        },
        confirmDelete: {
          title: 'Delete Company',
          text: 'Do you confirm the deletion of this Company?',
        },
        titleMessageError: 'The following errors have been reported:',
      },
      registerDigitalCertificate: {
        pageTitle: 'Uploading Digital Certificates',
        pageSubtitle: 'Select a company or register a new one to send the Digital Certificate.',
        dropdown: {
          label: 'Select a company',
          placeholder: 'Select a company',
        },
        dropzone: 'Drag the Digital Certificate file (.PFX or .P12) here or click to select it',
        dropzoneDuplicate: 'Certificate {name} has already been added',
        msgIncorrectFile: 'Invalid file extension',
        msgCorruptCertificate: 'The digital certificate selected for upload is corrupt!',
        modal: {
          title: 'Authorize Upload Certificate(s)',
          subtitle: 'To upload the certificate(s), it is necessary to inform the password for authorization.',
        },
        headers: {
          document: 'CNPJ',
          companyName: 'Corporate Name',
          fileName: 'File name',
          password: 'Password',
          validity: 'Validity',
          status: 'Status',
          SEFAZNoteDownload: 'Download NFe/CTe SEFAZ',
        },
        table: {
          listMsgError: 'It was not possible to find the registered certificates',
          employeeNotFound: 'It was not possible to find the registered certificates. We couldn\'t find your record on the staff.',
        },
        form: {
          fields: {
            password: {
              label: 'Password',
            },
          },
        },
        msgDropzoneNotCompany: 'Select a company',
        msgSuccessInclude: 'Digital Certificates successfully included!',
        msgErrorInclude: 'Unable to add Digital Certificate',
        msgErrorEstablishmentNotFound: 'Company that owns the certificate not found, register the company before the certificate',
        msgErrorAlreadyExists: 'The certificate is already registered!',
        userMasterNotSameFromEstab: "The certificate sent doesn't belong to a company vinculated to the user!",
        passwordIncorrect: 'Invalid password!',
        confirm: {
          title: 'Delete Certificate',
          text: 'Do you confirm the deletion of this Digital Certificate?',
        },
        msgDeleteSuccess: 'Digital Certificate successfully deleted!',
        msgDeleteError: 'Could not delete Digital Certificate',
        titleMessageError: 'The following errors have been reported:',
        msgUpdateCanSEFAZNotaDownloadSuccess: 'Updated! you can download it from Sefaz for the Digital Certificate!',
        msgUpdateError: 'Unable to update Digital Certificate',
      },
      integrations: {
        pageTitle: 'Do you have any Integration System?',
        pageSubtitle: 'We need to integrate with your ERP and/or with the Software you use:',
        registerSystems: 'Register Systems',
        clickToRegister: 'Tap the button above to register an Integration System',
        modal: {
          title: 'Register Integration System',
          btnCancel: 'Cancel',
          btnRegister: 'Cadastrar',
          btnUpdate: 'Update',
          form: {
            selectCompaniesLabel: 'Select the companies to register the integration system:',
            selectCompaniesPlaceHolder: 'Select companies',
            system: 'System',
            systemLabel: 'Select a System',
            systemPlaceholder: 'Ex.: Questor,Protheus...',
            version: 'Version',
            versionPlaceholder: 'Ex.: 1.12.5',
            token: 'Token',
            integrationToken: 'Integration Token',
            integrationTokenPlaceholder: 'Ex.: 2222222',
            user: 'User',
            userPlaceholder: 'User',
            password: 'Password',
            passwordPlaceholder: 'Enter the password',
            ip: 'IP Adress',
            ipPlaceholder: 'Ex.: 00.000.000.00',
            port: 'Port',
            portPlaceholder: 'Ex.: 8080',
            notOwn: 'I don\'t have',
            informAfter: 'Inform later',
            selectFinalities: 'Select the purpose(s)',
            companyDb: 'Bank layout',
            companyDbPlaceHolder: 'Ex.: BANK',
            retryLabel: 'In case of integration failure, restart the process every:',
            retryPlaceholder: 'Ex.: 01:30',
            optionsLabel: 'Database connection',
          },
          msgCreateSuccess: 'Integration System successfully inserted',
          msgCreatError: 'Unable to insert the Integration System',
          msgUpdateSuccess: 'Integration System successfully updated',
          msgUpdateError: 'Unable to update the Integration System',
        },
        headers: {
          systems: 'Systems',
          version: 'Version',
          integrationToken: 'Integration Token',
          user: 'User',
          password: 'Password',
          ipAndPort: 'IP / Port',
          finality: 'Goal',
        },
        body: {
          tooltip: {
            delete: 'Delete',
            edit: 'Edit',
          },
        },
        confirmation: {
          title: 'Delete Integration System',
          message: 'Confirm the Integration System deletion.',
          msgSuccess: 'Integration System successfully excluded',
          msgError: 'Unable to delete Integration System.',
        },
      },
      finish: {
        message: 'Setup completed with SUCCESS!',
        buttonText: 'Access my ROIT Dashboard',
      },
    },
    onboardingBank: {
      returnAdmin: 'Back to ROIT Admin',
      downloadCsvInfo: 'Download will start soon',
      downloadCsvError: 'Unable to download',
      start: {
        hello: 'Hi',
        greetings: 'The future has arrived with ROIT!',
        message: "From now on, we'll show you how to make the settings to get the best experiences and great results with the Roit's HYPERAUTOMATION Flow!",
        buttonText: 'Let\'s start?',
        support: 'Support',
      },
      accountantRobot: {
        csv: {
          account: 'CHART_OF_ACCOUNTS_MODEL.csv',
          product: 'PRODUCT_LAUNCH_MODEL.csv',
          services: 'SERVICE_LAUNCH_MODEL.csv',
        },
        text: {
          how: 'How to configure the Accounting Robot:',
          first: 'In the same way as the fiscal robot, we are going to enrich the accounting robot. This will help our Artificial Intelligence to find the best ranking opportunities for each document that passes through your company.',
          second: 'In this case, the information will be added in three documents - which we also leave template files for download. The first one is the Chart of Accounts and the following ones are for adding product and service launches.',
          third: 'After filling in, just import. This data can also be validated later!',
        },
      },
      taxRobot: {
        csv: {
          companies: 'COMPANIES_MODEL.csv',
        },
        text: {
          how: 'How the Fiscal Robot works:',
          first: 'Enriching the Fiscal Robot allows training neural networks to deliver the best Fiscal Rules for your company\'s scenarios.=',
          second: 'Therefore, we will need you to import your company data into a template that we provide below. So that when importing it in the configuration everything happens in the best possible way.',
          third: 'Do not worry! An area will be available where you can carry out a validation with a small excerpt from this file.',
        },
      },
    },
    configHomeScreen: {
      start: {
        hello: 'Are we now going to start the settings that will revolutionize your accounting?',
        ready: {
          firstNormal: 'Now that you know how the ',
          secondBold: 'Tax and Accounting Robots work, ',
          thirdNormal: 'it\'s time to configure!',
        },
        message: {
          firstNormal: 'Remember to use the ',
          secondBold: 'template files ',
          thirdNormal: 'as well as access validation sessions, where you can perform small tests to avoid inconsistencies.',
        },
        technology: {
          firstNormal: 'With the help of ',
          secondBold: 'Artificial Intelligence ',
          thirdNormal: 'and the mapped processor, we will be able to deliver an incredible experience. Let\'s get started?',
        },
        buttonText: 'Access and configure',
      },
    },
    welcomeVideo: {
      start: {
        title: {
          normal: 'Understand the ',
          bold: 'reverse flow of accounting and financial management!',
        },
        message: 'With artificial intelligence, we automate steps and build a process with audited information in real time for strategic management.',
        stepText: 'Instructional Video',
        buttonText: 'Next',
      },
    },
    integrations: {
      title: 'Integrate with your ERP and/or with the Software you use:',
      msgError: 'Unable to find Integration System data',
    },
    requiredField: 'Required field',
    notInformed: 'Not informed',
    date: 'The field is not valid',
    ascii: 'The {_field_} field must contain ASCII characters only.',
    ip: 'The {_field_} field must be a valid IP',
    port: 'The {_field_} field must be a valid port',
    email: 'E-mail',
    cpom: 'CPOM',
    yes: 'Yes',
    no: 'No',
    sn: 'Chooser SN',
    mei: 'MEI',
    irs: 'Situation with the Internal Revenue Service',
    supplier: 'Provider',
    taker: 'Borrower',
    iss: 'ISS',
    irrf: 'IRRF',
    csll: 'CSLL',
    cofins: 'COFINS',
    pis: 'PIS',
    inss: 'INSS',
    aliquot: 'Aliquot (%)',
    value: 'Value',
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Awaiting payment',
    paid: 'Paid',
    overdue: 'Overdue',
    state: 'State',
    city: 'City',
    file: 'File',
    establishment: 'Establishment',
    categorization: 'Categorization',
    competence: 'Competence',
    error: 'Error',
    success: 'Success',
    fail: 'Fail',
    processing: 'Processing',
    inssValue: 'INSS Value',
    quantity: 'Quantity',
    add: 'Add',
    totalValue: 'Amount',
    bankingData: 'Bank Data',
    bank: 'Bank',
    operation: 'Operation',
    agency: 'Agency',
    account: 'Account',
    demandant: 'Demandant',
    back: 'back',
    cancel: 'cancel',
    pay: 'Pay',
    invalidCNPJ: 'Invalid CNPJ',
    attention: 'Attention',
    proceed: 'Proceed',
    select: 'Select',
    regular: 'Regular',
    irregular: 'Irregular',
    nonDeductible: 'Indeductible',
    search: 'Search',
    delete: 'Delete',
    disable: 'Disable',
    disabled: 'Disabled',
    deleteAll: 'Delete All',
    inactivateAll: 'Disable All',
    selectAll: 'Select All',
    register: 'Register',
    warning: 'Warning',
    save: 'Save',
    edit: 'Edit',
    info: 'Information',
    confirm: 'Confirm',
    authorize: 'Authorize',
    remove: 'Remove',
    maxUpload: 'Maximum of {max} upload(s)',
    update: 'Update',
    certificate: 'Certificade',
    password: 'Password',
    invalidCreditCard: 'Invalid Credit Card',
    invalidDomain: 'Invalid domain',
    requiredChips: 'Required field. After typing the value press Enter',
    invalidCreditCardWithFlags: 'Invalid Credit Card.<br/><u>Allowed Flags</u>: {allowedFlags}',
    rating: 'Rating',
    commentary: 'Commentary',
    close: 'Close',
    and: 'and',
    username: 'Username',
    reprocessCPOMMessage:
      'CPOM needs to be reprocessed! <br/> This screen will close, access this document again in a moment.',
    disableUser: 'Disable User',
    errorStates: 'Unable to fetch the states',
    validation: 'Validation',
    noResultsFound: 'No Results Found',
    MyProductsTemplate: {
      myProducts: 'My Products',
      responsibles: 'Manage Responsibles',
    },
    MyProductsPage: {
      check: 'Check your contracted products:',
      tooltip: 'Contracts and<br> Additive Terms',
      redirectText: 'Access Website',
      email: {
        tooltip: 'Documents by email',
        modal: {
          header: {
            register: 'Email or domain registration',
            edit: 'Edit email or domain',
          },
          description: "Register the emails/domains responsible for receiving the documents. The documents that arrive at this address will be directed to the Roit's HYPERAUTOMATION Flow.",
          domainLabel: 'Domain',
          emailLabel: 'Email',
          placeholder: 'Enter one email or domain at a time',
          requestError: 'Unable to register.',
          requestFieldsError: 'Select an option and fill in the field.',
          requestSucess: 'Registration successfully completed.',
          alredyExistsError: 'Email or domain already registered!',
          type: 'Type',
          address: 'Address',
          roitEmailError: 'Email registration docs@roit.com.br is not allowed',
        },
      },
      configButton: 'Initial Settings',
      discover: {
        message: 'To use the <b>{field}</b>, we need to set it up in a few steps, let\'s go?',
        btnStart: 'Begin Configuration',
        btnDiscover: 'I want to know',
        products: {
          bank: "With <b>ROIT</b> we unify accounting, tax management and financial management with automated processes and artificial intelligence to make your company's day to day easier!",
          people: 'Thhe <b>ROIT People</b> brings integrated solutions for your company, with people management with an app that connects HR, managers and employees',
        },
      },
      headers: {
        module: 'Module',
        quantity: 'Quantity Contracted',
        validity: 'Validity',
        plan: 'Plan',
        status: 'Status',
      },
      actions: {
        contractsAndAdditiveTerms: {
          msgError: 'Error when consulting contractual information',
        },
        findProducts: {
          msgError: 'Error querying product information',
        },
      },
      cancelProduct: {
        title: 'Product Cancellation',
        description: 'We would like to understand a little more the reason for canceling the {field} module, We have already activated our support who will contact you soon ;)',
        questionRatingProduct: 'In the meantime, could you help us by evaluating how satisfied you are with this module?',
        questionCommentary: 'Would you like to leave us any comments?',
        sendMessage: 'Send',
        successFeedback: 'We will contact you shortly to finalize your request.',
        veryUnsatisfied: 'Very Unsatisfied',
        verySatisfied: 'Very Satisfied',
        actions: {
          msgSuccess: 'Request sent successfully',
          msgError: 'Error requesting module cancellation',
        },
        cancelRequest: 'Request Cancellation',
      },
    },
    ManagerResponsible: {
      title: 'Register and manage those responsible for the products.',
      subtitle: 'Registered people will receive invitations by email in order to access them.',
      new: 'Register Responsible',
      headers: {
        responsible: 'Responsible',
        mail: 'E-mail',
        job: 'Job',
        products: 'Products',
      },
      addNewResponsible: {
        msgSuccess: 'Responsible registered successfully',
        msgError: 'Error registering responsible person',
      },
      updateUserAdmin: {
        msgSuccess: 'Responsible record updated successfully',
        msgError: 'Error when updating the responsible record',
      },
      deleteResponsible: {
        actionTitle: 'Delete Responsible',
        actionMessage: 'Do you want to delete the responsible below?',
        msgSuccess: 'Responsible deleted successfully',
        msgError: 'Error deleting the person responsible',
      },
    },
    MyCompaniesPage: {
      title: 'Check your Company(s) or register a new one:',
      newRegister: 'Register Company',
      groupCompany: {
        title: 'Group Companies',
        registerGroup: {
          msgSuccess: 'Group of companies successfully created!',
          msgWarning: 'Select at least one company to group',
          msgError: 'It was not possible to register the group of companies',
        },
        updateGroup: {
          msgSuccess: 'Group of companies successfully updated!',
          msgWarning: 'There must be at least one company in the group to update',
          msgError: 'Could not update company group',
        },
        deleteGroup: {
          title: 'Delete Group',
          message: 'Do you confirm the deletion of this Group?',
          msgSuccess: 'Group of companies successfully deleted!',
          msgError: 'It was not possible to delete the group of companies',
        },
        withoutGroup: {
          msgInfo: 'No companies without a group were found',
          msgError: 'It was not possible to search the companies',
        },
      },
      group: 'Group',
      groupCompanySubTitle: 'Create a Business Group and select the Companies to group them.',
      inputTitle: 'Group Name',
      inputPlaceholder: 'Define a name for the Group',
      searchCompany: 'Search Company',
      companiesGrouped: {
        msgError: 'It was not possible to search the Groups of Companies',
      },
      processingWarning: {
        title: 'Registration performed!',
        message: 'It is currently in processing, wait a few moments and reload the page',
      },
    },
    registerCompanyTable: {
      headers: {
        cnpj: 'CNPJ',
        razaoSocial: 'Corporate Name',
        city: 'City',
        uf: 'State',
        status: 'Status RFB',
        ie: 'State Registration',
        im: 'Municipal Registration',
        rt: 'Tax Regime',
        erpId: 'ERP ID',
        polling: 'Polling',
        retreat: 'Retreat',
      },
    },
    noRecordsInDataTable: 'No Records found',
    users: {
      index: {
        title: 'Register and manage users to access products',
        companySelectLabel: 'Select a group to see users',
        companySelect: 'Select a company',
        newRegister: 'Register Users',
        dropdown: {
          msgError: 'It was not possible to search for the Companies',
        },
        products: {
          msgError: 'Unable to fetch the products',
        },
        actions: {
          findUsers: {
            msgError: 'Unable to fetch users',
            msgWarning: 'A company must be selected to search its users',
          },
          disable: {
            one: {
              title: 'Disable User',
              message: 'Do you want to disable this user?',
              msgError: 'Unable to disable user',
              msgSuccess: 'User disabled successfully',
            },
            many: {
              title: 'Disable Users',
              message: 'Do you want to disable selected users?',
              msgError: 'Unable to disable users',
              msgSuccess: 'Users successfully disabled',
            },
          },
          enable: {
            one: {
              title: 'Enable User',
              message: 'Do you want to enable this user?',
              msgError: 'Unable to enable user',
              msgSuccess: 'Successfully enabled user',
            },
            many: {
              title: 'Enable Users',
              message: 'Do you want to enable this users?',
              msgError: 'Unable to enable users',
              msgSuccess: 'Successfully enabled users',
            },
          },
          save: {
            msgSuccess: 'User registered successfully',
            msgError: 'Unable to register user',
            alreadyRegistered: 'User already registered',
          },
          update: {
            msgSuccess: 'User data updated successfully',
            msgError: 'Unable to update user data',
          },
          importUser: {
            msgSuccess: 'Successfully registered users',
            msgError: 'Unable to register users',
          },
          disableUser: 'Disable user',
          enableUser: 'Enable user',
        },
        updateUser: 'User Update',
        addUserModal: {
          fields: {
            company: {
              label: 'Link group to user',
              placeholder: 'Select a group',
            },
            name: {
              label: 'Name',
              placeholder: 'Ex: John',
            },
            surname: {
              label: 'Surname',
              placeholder: 'Ex: Doe',
            },
            occupation: {
              label: 'Job',
              placeholder: 'Ex: Tax Analyst',
            },
            mail: {
              label: 'E-mail',
              placeholder: 'name@yourcompany.com',
            },
            profile: {
              label: 'Profile',
              placeholder: 'Select profiles',
              app: 'BANK - APP',
              esteiramagica: 'BANK - Magic Flow',
              bankadminuserandcontactsmenu: 'BANKAdmin - Users and Contacts',
              bankadminmyproductsmenu: 'BANKAdmin - My Products',
              bankadminmycompaniesmenu: 'BANKAdmin - My Companies',
              bankadminintegrationsmenu: 'BANKAdmin - Integrations',
              bankadmindigitalcertificatesmenu: 'BANKAdmin - Digital Certificates',
              bankadminbillingmenu: 'BANKAdmin - Billing',
              bankadminaccesspassmenu: 'BANKAdmin - Access Pass',
            },
          },
          companySelectLabel: 'Select a company to link to user',
          tabRegisterUser: 'User Register',
          tabImportUser: 'Import Users (batch)',
          selectProfile: 'Select Profile',
          btnImportLabel: 'Import',
          importMessage: 'To import users, select a profile, download the corresponding default template, fill it in and drag to the field below',
        },
        usersTable: {
          user: 'User',
          occupation: 'Occupation',
          company: 'Company',
          mail: 'E-mail',
          profile: 'Profile',
          status: 'Status',
        },
        profilesModal: {
          title: 'User Profiles',
        },
      },
    },
    billing: {
      sections: {
        billing: {
          title: 'Billing',
          trackDetailedConsumption: 'Follow the detailed Consumption, by product:',
          filterByPeriod: 'Filter by Period',
          viewInvoice: 'View Receipt',
          defaultGroupName: 'Default Group Name',
          businessGroupTable: {
            headers: {
              cnpj: 'CNPJ',
              product: 'Product',
              period: 'Consumption Period',
              plan: 'Plan',
              amountConsumed: 'Amount Consumed',
              totalValue: 'Total Amount',
              dueDate: 'Due Date',
              status: 'Status',
              actions: 'Actions',
              emission: 'Emission',
            },
            sumary: {
              totals: 'Overall',
            },
            text: {
              unitValue: 'Unitary value: ',
              viewBilling: 'View Billing',
              viewCard: 'Credit Card',
              viewInvoice: 'Invoice',
            },
          },
          listBills: {
            msgWarning: 'Unable to list billing',
            msgError: 'Unable to list billing',
          },
          makePayment: {
            msgWarning: 'There is no ticket available for the product or period',
            msgError: 'It was not possible to obtain the bank slip for this group',
          },
          getBills: {
            msgWarning: 'There is no billing available for the product or period',
            msgError: 'It was not possible to obtain the billing statement file for this group',
          },
          listBillsByPeriod: {
            msgWarning: 'Unable to list billing by period.',
            msgError: 'Unable to list billing by period.',
          },
          detailModal: {
            title: 'Invoice Details',
            info: {
              identifier: 'CPF/CNPJ',
              company: 'Company',
              product: 'Product',
              releaseDate: 'Release Date',
              dueDate: 'Due Date',
              modality: 'Modality',
              amount: 'Amount',
            },
            table: {
              product: 'Product',
              consumed: 'Qty Consumed',
              price: 'Price',
              surplus: 'Surplus',
              minimumValue: 'Minimum Value',
              total: 'Amount',
            },
          },
          receiptPayment: {
            title: 'PROOF OF PAYMENT',
            value: 'VALUE',
            paymentMethod: 'PAYMENT METHOD',
            getChargeFail: 'Unable to display receipt',
            attempts: 'ATTEMPTS',
            cardInfo: {
              title: 'CARD DATA',
              number: 'NUMBER OF CARD',
              flag: 'FLAG',
              holder: 'HOLDER',
              validity: 'VALIDITY',
            },
            payment: {
              title: 'PAYMENT',
              transaction: 'TRANSACTION',
              installment: 'INSTALLMENT',
              attempts: 'ATTEMPTS',
              idTransaction: 'TRANSACTION ID',
            },
            authorizationCode: 'AUTHORIZATION CODE',
            callCenter: 'Call Center ROIT',
            SAC: 'SAC 0800 942 3019',
            ombudsman: 'Ombudsman ouvidoria@roit.ai',
            Roit: 'ROIT S.A.',
            RoitCNPJ: 'CNPJ: 31.391.902/0001-20',
          },
          summaryPayment: {
            title: 'Summary for payment',
            total: 'Total',
            payByInvoice: 'Pay by Invoice',
            payByCreditCard: 'Pay by Credit Card',
            toPay: 'Pay',
          },
        },
        billingInformation: {
          title: 'Billing Information',
          paymentMethod: 'Payment Method',
          billingAddress: 'Billing Address',
          invoiceAddress: 'Invoice Address',
          noPaymentMethodRegister: 'No payment methods registered',
          addPaymentMethod: 'Add payment method',
          expiresIn: 'Expires in',
          actions: {
            list: {
              msgError: 'Unable to fetch payment methods',
            },
            edit: 'Edit',
          },
          manager: {
            card: {
              name: {
                label: 'Name',
                title: 'Name (same as printed on Card)',
                placeholder: 'John Doe',
              },
              number: {
                title: 'Card Number',
                placeholder: '0000 0000 0000 0000',
              },
              expiresIn: {
                title: 'Expiration Date',
                placeholder: '02/2027',
              },
              securityCode: {
                title: 'Security Code',
                placeholder: '000',
              },
              isMain: 'Use this card as your primary payment method',
              addNewCard: 'Add New Card',
            },
            name: 'Name',
            surname: 'Surname',
            company: 'Company',
            email: 'E-mail',
            postcode: 'Zip Code',
            searchPostcode: 'Search Zip code',
            address: 'Address',
            addressNumber: 'Number',
            addressInformation: 'Address Information',
            district: 'District',
            city: 'City',
            state: {
              title: 'State',
              placeholder: '-',
            },
            country: 'Country',
            useTheSameAddressToInvoice: 'Use same address for Invoice',
            countryName: 'EUA',
            actions: {
              save: {
                msgSuccess: 'Payment method saved successfully',
                msgError: 'Unable to save payment method',
              },
              update: {
                msgSuccess: 'Payment method updated successfully',
                msgError: 'Could not update payment method',
              },
              deleteCreditCard: {
                title: 'Delete payment method',
                message: 'Do you want to delete this payment method?',
                msgSuccess: 'Payment method deleted successfully',
                msgError: 'Could not delete payment method',
              },
            },
          },
        },
      },
    },
    table: {
      mainLabelEmptyFlow: 'No Records',
      secondaryLabelEmptyFlow: 'No data to display',
    },
    managerProfile: {
      personalInformations: 'Personal Informations',
      userAndPassword: 'User and Password',
      myAccount: 'My Account',
      changeCover: 'Change Cover',
      toogleMenu: {
        photoChange: 'Change Photo',
        photoDelete: 'Delete Photo',
        confirmationModal: {
          deleteAvatar: {
            title: 'Delete profile photo',
            message: 'Confirm profile photo deletion?',
          },
        },
      },
      fields: {
        user: {
          name: {
            label: 'Name',
            placeholder: 'Ex: John',
          },
          surname: {
            label: 'Surname',
            placeholder: 'Ex: Doe',
          },

          mail: {
            label: 'E-mail',
            placeholder: 'name@yourcompany.com',
          },
          phone: {
            label: 'Phone',
            placeholder: 'Ex: (99) 9999-9999',
          },
          cellphone: {
            label: 'Cellphone',
            placeholder: 'Ex: (99) 99999-9999',
          },
        },
        address: {
          zipcode: {
            label: 'Zip Code',
            placeholder: 'Ex: 99.999-9999',
          },
          zipcodeConsultation: {
            label: 'Consult Zip Code',
            placeholder: 'Ex: 99.999-9999',
          },
          street: {
            label: 'Street',
            placeholder: 'Ex: Street Name',
          },
          number: {
            label: 'Nº',
            placeholder: 'Ex: 309',
          },
          complement: {
            label: 'Complement',
            placeholder: 'Ex: Apartment, 2º floor',
          },
          district: {
            label: 'Distict',
            placeholder: 'Ex: District Name',
          },
          city: {
            label: 'City',
            placeholder: 'Ex: New York',
          },
          state: {
            label: 'State',
            placeholder: 'New York',
          },
          country: {
            label: 'Country',
            placeholder: 'Ex: EUA',
          },
        },
        enterprise: {
          cnpj: {
            label: 'CNPJ:',
            placeholder: 'Ex: 99.999.999/9999-99',
          },
          company: {
            label: 'Company',
            placeholder: 'Company Name',
          },
        },
      },
      getProfile: {
        msgError: 'Unable to fetch profile data',
      },
      updateProfile: {
        msgValidation: 'Are there any mandatory field(s) or invalid information(s)',
        msgError: 'Unable to update user data',
        msgSuccess: 'User data updated successfully',
      },
    },
    taxInvoiceInboundPage: {
      header: {
        title: 'Register and configure automatic taxInvoiceInbound for companies (Headquarters/Branches).',
      },
      register: {
        headerRegister: 'Register Automatic taxInvoiceInbound',
        headerEdit: 'Edit Automatic taxInvoiceInbound',
        selectCertificate: 'Select a Certificate',
        emptyCertificate: 'No certificate',
        defaultCertificate: 'Default Certificate',
        company: 'Company',
        description: 'Select certificates and taxInvoiceInbound options below.',
        xmls: "Science Auto. from XML's",
        table: {
          alias: {
            NFe: 'NFe',
            NFSe: 'NFSe',
            CTe: 'CTe',
            certificate: 'Certificate',
            branches: 'Branches',
            corporateName: 'Corporate Name',
          },
        },
      },
      companiesTable: {
        enableInbound: 'Enable Inbound',
        alias: {
          companies: 'Companies',
          corporateName: 'Corporate Name',
          certificate: 'Certificate',
          taxInvoiceInbound: 'Automatic taxInvoiceInbound',
          automaticScience: 'Automatic Science',
          status: 'Status',
        },
      },
    },
  },
  userWithoutPermissionToAccessThisPlatform: 'User without permission to access this platform',
  noOptionsAvailable: 'No options available',
};
